<template>
  <div class="main-content">
    <div class="section post-section">
      <div class="row">
        <div class="full">
          <div class="pst-block">
            <div class="pst-block-main">
              <div class="post-content">
                <article>
                  <div class="post-main-img">
                    <figure v-if="$store.state.project.big_img_articles.length">
                      <img :src="$store.state.project.big_img_articles[0].get_img.media_server_url + $store.state.project.big_img_articles[0].get_img.url" :alt="$store.state.project.title || $store.state.project.name" class="adaptive" style="height:582px;width:100%;object-fit:contain"/>
                    </figure>
                    <figure v-else>
                      <img :src="$store.state.system_data.base_url + '/img/placeholder.png'" :alt="$store.state.project.title || $store.state.project.name" class="adaptive" style="height:582px;width:100%;object-fit:contain"/>
                    </figure>
                    <div class="post-main-overlay">
                      <div class="post-main-data">
                        <h2 class="title-21">{{ $store.state.project.title || $store.state.project.name }}</h2>
                        <div class="meta-tp-5">
                          <div class="date-tp-4"><i class="li_clock"></i>{{ $store.state.project.updated_at | human_date }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="small-gallery" v-if="$store.state.project.big_img_articles.length > 0">
                    <template v-for="(item, index) in $store.state.project.big_img_articles">
                      <div :key="index" class="item" v-if="index !== 0" style="margin-bottom: 5px;">
                        <figure>
                          <img :alt="($store.state.project.title || $store.state.project.name)" :src="item.get_img.media_server_url + item.get_img.url" @click="fullImage(index)" class="adaptive" height="168" width="230" style="object-fit: cover; height: 250px; cursor: pointer;">
                        </figure>
                      </div>
                    </template>
                  </div>
                  <div v-html="$store.state.project.description" v-if="$store.state.project.description"></div>
                  <div v-html="$store.state.project.content" v-if="$store.state.project.content"></div>
                  <div class="ya-share2-wrapper">
                    <p>Поделиться: </p>
                    <yandex-share :services="['vkontakte','facebook','odnoklassniki','twitter','whatsapp']" />
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
        <div class="gallery-modal" v-if="$store.state.project.big_img_articles && show_modal">
          <div @click="closeModal()" class="gallery-modal-background" title="Нажмите чтобы закрыть"></div>
          <div class="gallery-modal-wrapper">
            <div class="gallery-modal-image-container">
              <img :alt="$store.state.project.name" :src="modal_content.image" :title="$store.state.project.name" class="gallery-modal-image">
              <div class="gallery-modal-controls bvi-hide">
                <button @click="closeModal()" @touch="closeModal()" class="gallery-modal-close" title="Нажмите чтобы закрыть" type="button">
                  <svg fill="none" height="20" viewBox="0 0 21 20" width="21" xmlns="http://www.w3.org/2000/svg">
                    <rect fill="white" height="25.6198" transform="matrix(0.701403 -0.712765 0.701403 0.712765 0.320312 1.73913)" width="2.43998"></rect>
                    <rect fill="white" height="25.6198" transform="matrix(-0.701403 -0.712765 -0.701403 0.712765 19.6816 1.73913)" width="2.43998"></rect>
                  </svg>
                </button>
                <button @click="nextImage(0)" class="gallery-modal-controls-button left-button" title="Предыдущий элемент" type="button"></button>
                <button @click="nextImage(1)" class="gallery-modal-controls-button right-button" title="Следующий элемент" type="button"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YandexShare from '@cookieseater/vue-yandex-share';

export default {
  name: 'Project',
  metaInfo () {
    return this.$seo(this.$store.state.project.title || this.$store.state.project.name);
  },
  async asyncData (context) {
    await context.store.dispatch('projectPage', context.route.params.id);
  },
  components: { YandexShare },
  beforeMount () {
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 37) {
        this.nextImage(0);
      } else if (e.keyCode === 39) {
        this.nextImage(1);
      } else if (e.keyCode === 27) {
        this.closeModal();
      }
    });
  },
  data () {
    return {
      show_modal: false,
      modal_content: {
        image: '',
        active: 0,
        count: 0,
        base: ''
      }
    }
  },
  methods: {
    /**
     * Переключение изображений в открытой галереи фотографий
     * @param direction - направление переключения
     */
    nextImage (direction) {
      let img = this.$store.state.project.big_img_articles;
      if (direction === 0) {
        this.modal_content.active--;
        if (img[this.modal_content.active] === undefined) {
          this.modal_content.active = this.$store.state.project.big_img_articles.length - 1;
        }
      } else {
        this.modal_content.active++;
        if (img[this.modal_content.active] === undefined) {
          this.modal_content.active = 0;
        }
      }
      this.modal_content.image = img[this.modal_content.active].get_img.media_server_url + '/' + img[this.modal_content.active].get_img.url;
    },
    /**
     * Вывод модалки с изображением
     * @param i - id изображения на которое кликнули
     */
    fullImage (i) {
      this.show_modal = true;
      this.modal_content.active = i;
      this.modal_content.image = this.$store.state.project.big_img_articles[i].get_img.media_server_url + '/' + this.$store.state.project.big_img_articles[i].get_img.url;
      this.checkShowModal();
    },
    closeModal () {
      this.show_modal = false;
      this.checkShowModal();
    },
    checkShowModal () {
      document.querySelector('.header-tp-4').style.zIndex = this.show_modal ? '1' : '2';
    }
  }
};
</script>
